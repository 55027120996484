import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout';
import Helmet from "react-helmet"

export const query = graphql`
    query VptWebcastsWithDave {
        wpcontent {
            vptWebcastsWithDave(first: 99) {
                nodes {
                    id
                    title
                    uri
                    webinardate
                    youtubeId
                    speaker1
                    speaker1Profile
                    speaker2
                    speaker2Profile
                    speaker3
                    speaker3Profile
                    thumbnail
                }
            }
            seo{
                contentTypes {
                    vptWebcastWithDave {
                        metaDesc
                        title
                    }
                }
            }
            categories {
                nodes {
                  slug
                  name
                }
            }
        }
    }
`

const Home = ({ data,isEmpty }) => {
    const webinars = data.wpcontent.vptWebcastsWithDave.nodes
    const webinarSeo = data.wpcontent.seo.contentTypes.vptWebcastWithDave
    const pageTitle = webinarSeo.title
    const metaDesc = webinarSeo.metaDesc
    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/webinars/vantage-point-webcast-with-dave-ulrich/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/webinars/vantage-point-webcast-with-dave-ulrich/"></link>
            </Helmet>
            <section className="bg-indigo-100 mx-auto py-10 pb-0 sm:py-20 relative">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-6xl text-center lg:text-left px-6">
                   <div className = "w-full lg:w-1/2 justify-center lg:justify-start">
                        <h1 className = "text-2xl sm:text-3xl lg:text-7xl 2xl:text-7xl text-gray-100 font-bold"><span className="text-orange">Vantage Point</span> In Coversation with Dave Ulrich</h1>
                        <p className = "section-subtitle-white mt-3 mb-0">Join us on an enlightening journey through the complexities of employee recognition, organizational culture, and engagement. In this compelling podcast series, we explore the trio that underpins success in any organization, unveiling essential elements crucial for fostering a thriving workplace environment. Tune in and elevate your organizational skills!</p>
                   </div>
                   <div className = "w-full lg:w-1/2 flex justify-center md:justify-end items-center relative">
                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1708925459/gatsbycms/uploads/2024/02/dave-ulrich1.png" alt = "Vantage Point - In Coversation with Dave Ulrich" />
                    </div>
                </div>
            </section>
            
            <section className="webinar-list container px-6 lg:px-0 max-w-6xl mx-auto pt-5 sm:pt-10 pb-20 md:pb-40">
                <div class="cat-content">
                    <div class="cat-posts active" id="cat1">
                        <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                            {webinars.map(webinar => (
                                <article key={webinar.id}>
                                    <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                                        <Link to={`${webinar.uri}`} >
                                            <img className="p-0 rounded-lg webinar-video-thumb" src={`${webinar.thumbnail}`} alt={webinar.title} />
                                        </Link>
                                        <Link to={`${webinar.uri}`} >
                                            <div className="bg-white py-6 h-full topcard no-br">
                                                <p className="text-orange text-base">[On-demand-recording]</p>
                                                <h2 className="box-title-v1 font-bold mb-3 line-clamp-3 -mt-3" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                                                <p className="box-subtitle mb-2 uppercase">On <span dangerouslySetInnerHTML={{ __html: webinar.webinardate }} /></p>
                                                <div className="with-sep text-gray-200 text-base">With</div>
                                                <div className="md:flex justify-around md:mb-10">
                                                    <div className="box-subtitle w-full md:h-12">
                                                        <span className="font-bold text-lg">{webinar.speaker1}</span> <br/> <span className="line-clamp-3">{webinar.speaker1Profile}</span>
                                                    </div>
                                                    {webinar.speaker2 && <div className="box-subtitle w-full md:h-12 mr-6">
                                                        <span className="font-bold text-lg">{webinar.speaker2}</span> <br/> <span className="line-clamp-3">{webinar.speaker2Profile}</span>
                                                    </div>}
                                                    {webinar.speaker3 && <div className="box-subtitle w-full md:h-12">
                                                        <span className="font-bold text-lg">{webinar.speaker3}</span> <br/> <span className="line-clamp-3">{webinar.speaker3Profile}</span>
                                                    </div>}
                                                </div>
                                                
                                            </div>
                                        </Link>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className=" p-10 md:p-14 relative z-9 overflow-hidden" style={{"background": "#414171"}}>
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default Home